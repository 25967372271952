<template>
  <div class="setupAccount">
    <SetupAccountComponent @submit="submit" />
  </div>
</template>
<script>
import SetupAccountComponent from '@m/account/components/SetupAccount';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { getAccountsGroup, dummyBusinessId } from '@m/account/utils/account';
export default {
  name: 'SetupAccount',
  components: {
    SetupAccountComponent
  },
  computed: {
    ...mapGetters('account', [
      'getCreateAccountData',
      'getAllAccount',
      'getAddAccountBackPage'
    ]),
    ...mapGetters('kyb', ['getBusinessList'])
  },
  created(){
    this.fetchAllAccounts();
  },
  methods: {
    ...mapActions('account', ['createAccount', 'listAllAccounts']),
    ...mapMutations('transaction', ['setRecentTransactions']),
    ...mapMutations('account', ['setGroupedAccounts']),
    submit(data) {
      const loader = this.showLoader();
      data.type = this.getCreateAccountData.type;
      let payload = {
        businessId: this.getCreateAccountData.businessId,
        ...data
      };
      this.createAccount(payload)
        .then((acc) => {
          setTimeout(() => {
            if (this.getAddAccountBackPage) {
              const accounts = getAccountsGroup(
                this.getAllAccount.length === 0 ? [acc] : this.getAllAccount,
                this.getBusinessList,
                this.$personalChecking,
                this.$businessChecking
              );
              let groupedAccounts = [];
              if (acc.type === 'personalChecking') {
                groupedAccounts = accounts[dummyBusinessId] ? accounts[dummyBusinessId].accounts : [];
              } else {
                groupedAccounts = accounts[acc.businessId] ? accounts[acc.businessId].accounts : [];
              }
              groupedAccounts.push(acc);
              this.setGroupedAccounts(groupedAccounts);
            }
            this.$router.push('/account/accountCreated');
            this.setRecentTransactions([]);
          }, 0);
        })
        .catch((e) => {
          console.log(e);
          this.apiErrorMessage(e);
        })
        .finally(() => {
          loader.close();
        });
    },
    fetchAllAccounts() {
      // if(this.getAllAccount == null || (this.getAllAccount && this.getAllAccount.length == 0)) {
      const loader = this.showLoader();
      this.listAllAccounts(null)
        .then(() => {})
        .catch((e) => {
          console.log(e);
          this.apiErrorMessage(e);
        })
        .finally(() => {
          loader.close();
        });
      // }
    },
  }
};
</script>
